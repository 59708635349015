<template>
  <div>
    <div v-if="allowAdd && userId && table.data.length" class="message is-dark">
      <div class="message-body has-padding-75">
        <apart>
          <p>Add a website on behalf of this user.</p>
          <button
            class="button is-dark is-rounded is-outlined"
            @click="addSite"
          >
            Add site
          </button>
        </apart>
      </div>
    </div>

    <b-table
      :data="table.data"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      hoverable
      striped
      class="has-vertical-align-middle"
      @sort="onSort"
    >
      <template slot="empty">
        <no-results
          v-if="!allowAdd"
          icon="box-open"
          title="No sites"
          message="There are no sites to show."
        />

        <no-results
          v-else
          :cta="{ label: 'Add site' }"
          icon="box-open"
          title="No sites"
          message="There are no sites to show."
          @click="addSite"
        />
      </template>

      <template slot-scope="props">
        <b-table-column label="Status" field="isDeleted" width="100">
          <status-label :status="props.row.isDeleted ? 'deleted' : 'active'" />
        </b-table-column>

        <b-table-column label="Site" field="fqdn" sortable>
          <router-link
            :to="{ path: `/sites/magic/${props.row._id}` }"
            class="has-text-dark"
          >
            <strong>
              <u>{{ props.row.fqdn }}</u>
            </strong>
          </router-link>
        </b-table-column>

        <b-table-column
          label="Subscription"
          field="isSubscribed"
          width="150"
          sortable
        >
          <b-icon
            :icon="props.row.isSubscribed ? 'check' : 'times'"
            :type="props.row.isSubscribed ? 'is-success' : 'is-danger'"
            size="is-small"
            class="has-margin-left-25"
          />
        </b-table-column>

        <b-table-column
          label="Tasks"
          field="quota.totalTasks"
          class="has-text-centered"
          width="1"
          >{{
            props.row.quota ? props.row.quota.totalTasks || `–` : `–`
          }}</b-table-column
        >

        <b-table-column
          v-if="userId"
          label="Role"
          field="role"
          sortable
          width="150"
          >{{ getUserRole(props.row) }}</b-table-column
        >

        <b-table-column
          label="Software"
          field="software"
          sortable
          width="150"
          >{{ props.row.software }}</b-table-column
        >

        <b-table-column
          label="Created"
          field="dateCreated"
          sortable
          width="150"
        >
          <span v-if="props.row.dateCreated">{{
            $moment(props.row.dateCreated.toDate()).from()
          }}</span>
        </b-table-column>
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData"
    />
  </div>
</template>

<script>
import { getUserRole } from "@src/services/site";
import { mapState } from "vuex";

export default {
  name: "SitesTable",
  //mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    userId: {
      type: String,
      required: false,
      default: ""
    },
    allowAdd: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      table: {
        limit: 10,
        loading: false,
        complete: true,
        sort: { field: "dateCreated", direction: "desc" },
        data: [],
        results: [],
        page: 1,
        sortable: true
      }
    };
  },
  computed: {
    ...mapState({
      observingUserSites: state => state.sites.observingUserSites,
      sites: state => state.sites.observed
    }),
    allSites() {
      return this.$_.toArray(this.sites);
    },
    filteredSites() {
      if (this.userId)
        return this.$_(this.allSites)
          .filter(site => site.users.includes(this.userId))
          .orderBy([this.table.sort.field], [this.table.sort.direction])
          .value();

      return this.allSites;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onSort(field, direction) {
      this.table.sort = { field: field, direction: direction };
    },
    getData(loadMore = false) {
      this.table.loading = true;
      if (loadMore) {
        this.table.page++;
      }

      this.table.data = this.$_.take(
        this.filteredSites,
        this.table.page * this.table.limit
      );

      this.$nextTick(() => {
        this.table.loading = false;
      });
    },
    getUserRole(site) {
      let role = getUserRole({ site: site, userId: this.userId });

      return role.charAt(0).toUpperCase() + role.slice(1);
    },
    addSite() {
      const modal = this.$modal.open({
        component: () => import("@shared/sites/_addSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        events: {
          success: siteId => {
            modal.close();
            this.$router.push(`/sites/${siteId}`);
          }
        },
        props: {
          parent: this,
          userId: this.userId
        }
      });
    }
  }
};
</script>
